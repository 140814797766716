import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class RecaptchaProcessor extends BaseComponentProcessor {

    /**
     * Process incoming event
     */
    async processEvent() {
        try {
            const siteKey = this.context?.renderer?.a2u?.source?.recaptcha?.siteKey;

            if (!siteKey) {
                throw new Error('reCAPTCHA site key not set');
            }

            const token = await this.executeRecaptcha(siteKey);

            const status = await this.verifyToken(token);

            return this.processOutgoingLinks(this.context, this.block.id, null, status === 'passed' ? 'passed' : 'failed');
        } catch (e) {
            this.context.renderer.a2u.debugLogger.log({
                type: 'error',
                message: e.message,
                data: JSON.parse(JSON.stringify({
                    processNum: this.processNum,
                    diagram: { id: this.context?.currentDiagram?.block?.properties?.diagramComponentId },
                    component: {
                        id: this.block?.id,
                        properties: {},
                    },
                    data: {},
                })),
            });

            return this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }
    }

    /**
     * Executes the reCAPTCHA process using the provided site key.
     *
     * @param {string} siteKey - The site key for reCAPTCHA.
     * @returns {Promise<string>} - A promise that resolves with the reCAPTCHA token.
     * @throws {Error} - If reCAPTCHA is not loaded.
     */
    executeRecaptcha(siteKey) {
        return new Promise((resolve, reject) => {
            if (window.grecaptcha) {
                window.grecaptcha.ready(() => {
                    window.grecaptcha
                      .execute(siteKey, { action: 'submit' })
                      .then((token) => resolve(token))
                      .catch((error) => reject(error));
                });
            } else {
                reject(new Error('reCAPTCHA not loaded'));
            }
        });
    }

    /**
     * Verifies the reCAPTCHA token by sending it to the verification endpoint.
     *
     * @param {string} token - The reCAPTCHA token to verify.
     * @returns {Promise<Object>} - A promise that resolves with the verification response data.
     */
    async verifyToken(token) {
        const {data} = await this.context.app.client.getAxios().post(`${this.context?.renderer?.a2u?.authUrl}/recaptcha/verify`, [token]);

        return data;
    }
}
