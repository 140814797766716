<template>
  <atu-components-renderer :items="block.children" />
</template>

<script>

import AtuComponentsRenderer from "../../../AtuComponentsRenderer";

export default {
  name: "ControlListItem",
  components: {AtuComponentsRenderer},
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    block: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    values: {}
  }),

  created() {

    // Set values from model value
    this.values = Object.assign({}, this.modelValue);

    // Watch values change and update model
    this.$watch('values', () => {
      this.$emit('update:modelValue', this.values);
    }, {deep: true});
  },

  /**
   * Provide
   * @returns {{parentForm: *}}
   */
  provide() {
    return {
      parentControlList: this
    }
  },


  methods: {

    /**
     * Get value
     * @param block
     * @return {string}
     */
    getValue(block) {

      // Get block alias
      const alias = block.properties?.alias || block.id;

      // Return value
      return this.values[alias];
    },

    /**
     * Set value
     * @param block
     * @param value
     */
    setValue(block, value) {

      // Get block alias
      const alias = block.properties?.alias || block.id;

      // Set value
      this.values[alias] = value;
    }
  }
}

</script>
