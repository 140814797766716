import {BaseToolProcessor} from 'a2u-renderer-common/src/processors/AI/BaseToolProcessor.js';
import SchemaGeneratorDialog from './SchemaGeneratorDialog.vue';

/**
 * Code processor
 */
export class SchemaGeneratorProcessor extends BaseToolProcessor {
    toolName = 'ai:schema-generator';

    /**
     * Process incoming event
     */
    async processEvent() {
        if(this.app.renderer.a2u.runMode === "debug") {
            return this.debugResponse({
                result: {},
                cancel: {},
                error: {message: `Error calling tool ${this.toolName}`},
            });
        }

        const title = this.context.interpretString(this.block?.properties?.title);
        const prompt = this.context.interpretString(this.block?.properties?.instruction);
        const result_count = Number(this.context.interpretString(this.block?.properties?.count)) || 1;
        const schema = this.block?.properties?.schema?.schema || {};

        if (!prompt || !schema) {
            this._debugLog('Missing properties: prompt, schema');

            return;
        }

        this.context.$q.dialog({
            component: SchemaGeneratorDialog,
            componentProps: {
                title,
                prompt,
                resultCount: result_count,
                schema,
                callTool: async (...args) => this.context?.renderer?.a2u?.callTool(this.toolName, ...args),
                debugLog: (...args) => this._debugLog(...args),
                processOutgoingLinks: (...args) => this.processOutgoingLinks(this.context, this.block.id, ...args),
            },
        });
    }
}
