<template>
  <div class="json-preview">
      <json-node :node="filteredJsonSchema"/>
  </div>
</template>

<script>

import JsonNode from "./JsonNode.vue";

export default {
  name: "JsonPreview",
  components: {JsonNode},
  props: {
    jsonSchema: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    filteredJsonSchema() {

      // Filter json schema, remove fields unique_id, number
      // eslint-disable-next-line no-unused-vars
      const {unique_id, number, ...filteredSchema} = this.jsonSchema;

      // Return filtered schema
      return filteredSchema;
    }
  }
}

</script>
