<template>
  <q-dialog class="z-max" full-width full-height>
    <q-card class="flex column no-wrap">
      <div class="row justify-end">
        <q-btn flat round icon="close" v-close-popup />
      </div>

      <q-card-section class="col">
        <iframe class="full-height full-width block no-border" :src="url" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'DeviceNavigatorDialog',

  props: ['url'],
}
</script>
