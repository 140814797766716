<template>
  <q-dialog ref="editor" full-height full-width>
    <q-card class="flex column">
      <q-card-section class="flex justify-between items-center bg-primary text-white">
        <div class="text-subtitle1" v-text="title"/>

        <div>
          <q-btn flat round icon="close" @click="closeDialog" />
        </div>
      </q-card-section>

      <q-card-section class="col scroll">
        <div v-if="isBusy" class="flex items-center justify-center q-pt-lg">
          <q-spinner
            color="primary"
            size="3em"
          />
        </div>
        <template v-else-if="jsonSchema?.length">
          <template v-for="(item, idx) in jsonSchema" :key="idx">
            <div class="flex items-start justify-between no-wrap">
              <json-preview :json-schema="item" />

              <q-btn class="q-ml-sm" flat round icon="add" @click="applyResult(idx)" />
            </div>

            <q-separator v-if="(idx + 1) < jsonSchema.length" spaced/>
          </template>
        </template>
      </q-card-section>

      <q-separator spaced />

      <q-card-actions>
        <q-input v-model="userPrompt" autogrow filled class="full-width" :readonly="isBusy">
          <template #append>
            <q-btn icon="draw" flat @click="generateSchema" :loading="isBusy" />
          </template>
        </q-input>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import JsonPreview from './JSONPreview/JsonPreview.vue';

export default {
  name: 'SchemaGeneratorDialog',
  components: {JsonPreview},

  props: {
    title: {
      required: false,
      type: String,
      default: 'AI Composer',
    },
    prompt: {
      required: true,
      type: String,
    },
    resultCount: {
      required: true,
      type: Number,
    },
    schema: {
      required: true,
      type: Object,
    },
    callTool: {
      required: true,
      type: Function,
    },
    debugLog: {
      required: true,
      type: Function,
    },
    processOutgoingLinks: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      userPrompt: '',
      isBusy: false,
      jsonSchema: [],
    };
  },

  methods: {
    async generateSchema() {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;

      try {
        const {result} = await this.callTool({
          prompt: this.prompt,
          schema: this.schema,
          result_count: this.resultCount,
          ...(this.userPrompt ? {user_prompt: this.userPrompt} : {}),
          ...(this.jsonSchema ? {previews_result: this.jsonSchema} : {}),
        }, { sync: true });

        this.jsonSchema = Array.isArray(result) ? result : [result];
      } catch (e) {
        console.error('Error calling tool ai:schema-generator', e);

        this.debugLog(e);

        this.processOutgoingLinks(null, 'error');
      } finally {
        this.isBusy = false;
      }
    },

    closeDialog() {
      this.$refs.editor.hide();

      this.jsonSchema = [];

      this.processOutgoingLinks(null, 'cancel');
    },

    applyResult(idx) {
      const item = this.jsonSchema[idx];

      this.jsonSchema.splice(idx, 1);

      this.processOutgoingLinks(item, 'result');
    },
  },

  created() {
    this.generateSchema();
  }
}
</script>
