<template>

  <ab-flow-base-cmp class="navigation-panel row overflow-hidden" :block="block" :class="cmpClasses" :style="stylesString">
    <q-tabs
      v-model="tabValue"
      class="col"
      no-caps
      :switch-indicator="selPosition"
      @update:model-value="clickButton"
      :vertical="vertical"
      :inline-label="vertical"
      :style="{
        '--tabs-justify-content': tabsAlign
      }"
    >
      <template v-if="!isCollapsed">
        <template v-for="item of finalItems" :key="item.value">
          <q-tab :icon="getIcon(item)" :name="item.value" @click="onClickTab(item.value)">
            <label>{{ item.title }}</label>
          </q-tab>
        </template>
      </template>

      <q-btn-dropdown
        v-else
        class="navigation-panel-dropdown"
        auto-close
        stretch
        flat
        icon="menu"
      >
        <q-list :class="cmpClasses">
          <template v-for="item of finalItems" :key="item.value">
            <q-item clickable @click="onClickDropdown(item.value)">
              <q-item-section v-if="getIcon(item)" avatar style="min-width: 0;">
                <q-icon :name="getIcon(item)" />
              </q-item-section>

              <q-item-section>{{ item.title }}</q-item-section>
            </q-item>
          </template>
        </q-list>
      </q-btn-dropdown>
    </q-tabs>
  </ab-flow-base-cmp>

</template>

<script>
import {toRaw} from 'vue';
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../../components/renderMixins";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "NavigationPanelEditorCmp",
  data: () => ({
    tab: "",
    defaultTabValue: "",
    tabWidths: {},
    tabsContainerWidth: 0,
  }),
  computed: {

    /**
     * Current form control value
     */
    tabValue: {
      get: function () {

        // No var set
        if(!this.block?.properties?.field) return this.defaultTabValue;

        // Check for form values or regular model
        const res = this.getValue(this.block?.properties?.field);

        const tabValue = res === undefined || res === null ? this.defaultTabValue : res;

        const [parsedValue] = typeof tabValue === 'string' ? tabValue.split(':') : [tabValue];

        return parsedValue;
      },
      // setter
      set: function (newVal) {
        // No var set
        if(!this.block?.properties?.field) {
          this.defaultTabValue = newVal;
          return
        }

        // Set value
        this.setOperationValue(this.block?.properties?.field, 'set', newVal);
      }
    },

    /**
     * If vertical direction
     * @return {boolean}
     */
    vertical() {
      return ['left', 'right'].includes(this.block?.properties?.position)
    },

    /**
     * If up position
     * @return {boolean}
     */
    selPosition() {
      return ['right', 'down'].includes(this.block?.properties?.position)
    },

    /**
     * Items list
     * @return {*}
     */
    itemsList() {
      return (this.block?.properties?.items?.items || []).filter((item) => !item.visibility || (item.visibility?.valueType === 'none') || !!this.getValue(item.visibility))
        .map((item) => ({
          ...item,
          title: this.interpretString(item.title),
        }));
    },

    /**
     * This computed property is used to get the dynamic items from the block properties.
     * It first retrieves the value of the dynamicItems property from the block properties.
     * If the retrieved value is not an array, it wraps the value in an array.
     * It then filters the items, ensuring each item is an object and contains all the required keys.
     * The required keys are 'title', 'icon', 'image', and 'value'.
     * @return {Array} An array of items that are objects and contain all the required keys.
     */
    dynamicItems() {
      // Get the value of the dynamicItems property from the block properties.
      let items = toRaw(this.getValue(this.block?.properties?.dynamicItems, []));

      // If the retrieved value is not an array, wrap it in an array.
      if (!Array.isArray(items)) {
        items = [items];
      }

      // Filter the items, ensuring each item is an object and contains all the required keys.
      return items.filter(
        (item) => {

          // Check if item is object
          if(!item || typeof item !== 'object' ) return false;

          // Get required fields
          item.title = item.title || item.label || item.name || "No Name"
          item.value = item.value || item.id || "No value"

          // Return if title and value
          return item.title && item.value
        }
      );
    },

    /**
     * This computed property is used to get the final list of items.
     * It combines the items from the itemsList and dynamicItems computed properties.
     * The spread operator (...) is used to create a new array that includes all the items from both properties.
     * @return {Array} An array that includes all the items from the itemsList and dynamicItems computed properties.
     */
    finalItems() {
      return [
        ...this.itemsList,
        ...this.dynamicItems,
      ];
    },

    /**
     * Button color
     * @return {string}
     */
    buttonColor() {
      return this.block?.properties?.color || "";
    },

    /**
     * View mode
     * @return {string}
     */
    viewMode() {
      return this.block?.properties?.viewMode || 'icon';
    },

    /**
     * Checks if the block has a field property.
     *
     * @returns {boolean} True if the block has a field property, otherwise false.
     */
    hasField() {
      return !!this.block?.properties?.field;
    },

    /**
     * Computes the classes to be applied to the component.
     * It combines the classes from the getClasses method and additional classes
     * based on the disableJustifyAlignment and tabsAlign properties.
     *
     * @returns {Array} An array of class names.
     */
    cmpClasses() {
      return [
        this.getClasses(false, {contentAlign: true}),
        {
          'tabs-disable-justify-align': this.disableJustifyAlignment,
          'tabs-justify-content': !!this.tabsAlign,
        }
      ];
    },

    /**
     * Determines if the justify alignment should be disabled.
     * This is based on the `disableJustifyAlignment` property of the block.
     *
     * @returns {boolean} True if justify alignment is disabled, otherwise false.
     */
    disableJustifyAlignment() {
      return this.block?.properties?.disableJustifyAlignment === 1 && !this.vertical;
    },

    /**
     * Computes the alignment for the tabs.
     * If the tabs are vertical or justify alignment is not disabled, returns null.
     * Otherwise, returns the content alignment from the block properties.
     *
     * @returns {string|null} The alignment for the tabs or null.
     */
    tabsAlign() {
      if (this.vertical || !this.disableJustifyAlignment) {
        return null;
      }

      return this.block?.properties?.contentAlign || null;
    },

    /**
     * Determines if the navigation panel is collapsed.
     * @returns {boolean} True if the panel is collapsed, otherwise false.
     */
    isCollapsed() {
      return !!this.getValue(this.block?.properties?.collapsed);
    },
  },
  methods: {
    /**
     * Get icon
     * @param item
     * @return {string|undefined}
     */
    getIcon(item) {
      // If icon mode return icon
      if (this.viewMode === 'icon') {
        return typeof item.icon === 'string' ? item.icon : undefined;
      }

      // Get active image
      const activeImage = item.activeImage?.source_url || undefined;

      // If active tab and active image
      if (item.value === this.tabValue && activeImage) {
        return `img:${this.renderer.a2u.assetPath(activeImage)}`;
      }

      // Get image
      const image = item.image?.source_url || undefined;

      // Return image
      return image ? `img:${this.renderer.a2u.assetPath(image)}` : undefined;
    },

    /**
     * Click button
     */
    clickButton(val) {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, false, val)
    },

    /**
     * Handles the click event on a tab.
     * If the component has a field, the function returns early.
     * Otherwise, it triggers the clickButton method with the provided value.
     *
     * @param {string} val - The value of the clicked tab.
     */
    onClickTab(val) {
      if (this.hasField) {
        return;
      }

      this.clickButton(val);
    },

    /**
     * Handles the click event on a dropdown item.
     * Sets the tab value to the clicked item's value and triggers the clickButton method.
     *
     * @param {string} val - The value of the clicked dropdown item.
     */
    onClickDropdown(val) {
      this.tabValue = val;
      this.clickButton(val);
    },
  },

  watch: {
    /**
     * Watcher for tabValue.
     * Updates the teleport transition direction based on the index of the new and old tab values.
     *
     * @param {string} newVal - The new tab value.
     * @param {string} oldVal - The old tab value.
     */
    tabValue(newVal, oldVal) {
      const newIdx = this.finalItems.findIndex((item) => item.value === newVal);
      const oldIdx = this.finalItems.findIndex((item) => item.value === oldVal);

      this.getStorage('fragment').set('teleportTransition', newIdx > oldIdx ? 'left' : 'right');
    },
  },
}

</script>

<style lang="scss">
.navigation-panel {

  .q-tabs--vertical {
    .q-tab {
      justify-content: start;

      label {
        margin-left:5px;
      }
    }
  }

  .q-tab {
    flex-grow: 1;
    padding: 0 10px;

    .q-tab__content {
      padding-top:10px;
    }
  }

  &.tabs-disable-justify-align {
    .q-tab {
      flex: 0 !important;
    }
  }

  &.tabs-justify-content {
    .q-tabs__content {
      justify-content: var(--tabs-justify-content);
    }
  }

  .navigation-panel-dropdown {
    padding-top: 10px;

    .q-btn-dropdown__arrow {
      display: none !important;
    }
  }
}
</style>
