<template>
  <ab-flow-base-cmp :block="block" class="container-editor-cmp" :class="containerClasses" :style="stylesString">
    <q-scroll-area v-if="isScrollable" class="scrollable-parent" style="height: 100%;width: 100%">
      <div class="scroll-content" :class="scrollableClasses">
        <atu-components-renderer :items="children" />
      </div>
    </q-scroll-area>
    <atu-components-renderer v-else :items="children" />

  </ab-flow-base-cmp>
</template>

<script>
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp";
import AtuComponentsRenderer from "../../../AtuComponentsRenderer";
import {renderMixins} from "../../renderMixins";

export default {
  components: {AtuComponentsRenderer, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "ContainerEditorCmp",
    computed: {

      /**
       * Is scrollable
       * @return boolean
       */
      isScrollable() {
        return !!this.block.properties?.scrollable;
      },


      /**
       * Scrollable classes
       * @return {string}
       */
      scrollableClasses() {

        // Init styles
        let cntClasses = [];


        // Style according to direction
        switch (this.block?.properties?.direction || 'column') {
          case "column":
            cntClasses.push('dg-direction-column')
            break;
          case "row":
            cntClasses.push('dg-direction-row')
            break;
        }

        // Check for expanded mode
        if(this.block?.properties?.itemsAlign) cntClasses.push(`dg-items-${this.block?.properties?.itemsAlign}`)
        if(this.block?.properties?.contentAlign) cntClasses.push(`dg-justify-${this.block?.properties?.contentAlign}`)

        // Return classes
        return `${cntClasses.join(" ")} ${this.classesString}`;
      },

      /**
       * Container styles
       * @return {string}
       */
      containerClasses() {

        // Init styles
        let cntClasses = [];

        // Style according to direction
        switch (this.block?.properties?.direction || 'column') {
          case "column":
            cntClasses.push('dg-direction-column')
            break;
          case "row":
            cntClasses.push('dg-direction-row')
            break;
          case "stack":
            cntClasses.push('dg-direction-stack')
            break;
          case "grid":
            cntClasses.push('dg-direction-grid');
            cntClasses.push(`dg-grid-cols-${this.block?.properties?.gridCols || 1}`);
            break;
        }

        // Check for expanded mode
        if(this.block?.properties?.extended) cntClasses.push('col');

        // Hide scrollbar
        if (this.hideScrollbar) {
          cntClasses.push('_hide-scrollbar');
        }

        // Add overflow hidden if not scrollable
        if (!this.isScrollable) {
          // Bad solution, leads to unpredictable resizing, need to rethink
          //cntClasses.push('overflow-hidden');
        } else {
          cntClasses.push(`_direction-${this.scrollDirection}`);
        }

        // Return classes
        return `${cntClasses.join(" ")} ${this.classesString}`;
      },

      /**
       * Children list
       * @return {*}
       */
      children() {
        // Return regular children
        return this.block.children
      },

      /**
       * Determines if the scrollbar should be hidden.
       *
       * @return {boolean} True if the scrollbar should be hidden, otherwise false.
       */
      hideScrollbar() {
        return this.isScrollable && this.block.properties?.hideScrollbar === 1;
      },

      /**
       * Get the scroll direction.
       *
       * @return {string} The scroll direction, either 'vertical' or the value specified in block properties.
       */
      scrollDirection() {
        return this.block?.properties?.scrollDirection || 'vertical';
      },
  }
}

</script>

<style lang="scss">


.container-editor-cmp {
  position: relative;
  display: flex;


  .scroll-content {
    display: flex;
    align-items: inherit;
    justify-content: inherit;
  }

  &._hide-scrollbar {
    .q-scrollarea__thumb, .q-scrollarea__bar {
      display: none !important;
    }
  }

  ._direction-vertical {
    .scrollable-parent {
      .q-scrollarea__content {
        max-width: 100%;
      }
    }
  }

  &.dg-direction-grid {
    &:not(:has(> .repeater-cmp)) {
      display: grid;
      grid-template-columns: repeat(var(--dg-grid-cols, 1), 1fr);
    }
  }
}

.container-editor-cmp.dg-direction-stack {
  overflow: hidden;
}

.container-editor-cmp.dg-direction-stack > .editor-cmp {
  position: absolute;
  //width: 100%;
  //height: 100%;
}

.container-editor-cmp.dg-direction-stack > .container-editor-cmp {
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

// Expanded inside the stack container should take all the size of parent
.container-editor-cmp.dg-direction-stack > .col {
  width: 100%;
  height: 100%;
}


</style>
