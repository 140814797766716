import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";
import DeviceNavigatorDialog from './DeviceNavigatorDialog.vue';

/**
 * Device navigator
 */
export class DeviceNavigatorProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {

        // Get navigation url
        const navigationUrl = this.context.interpretString(this.block.properties?.navigationLink) || data?.input

        // If url is not a string
        if(typeof navigationUrl !== 'string') {
            // Log
            this.context.renderer.a2u.showError(`DeviceNavigator, navigationUrl is not a string: ${JSON.stringify(navigationUrl)}`)
            return;
        }

        // Get protocol
        const protocol = navigationUrl?.split(':')?.[0];

        // Allowed protocols
        const allowedProtocols = ['http', 'https'];

        // Check if protocol is allowed
        if (!allowedProtocols.includes(protocol)) {
            alert("Unknown protocol")
            return;
        }

        // If web platform
        if (this.context.renderer.a2u.getPlatform() === 'web') {
            if(this.block?.properties?.openInDialog)
                this.context.$q.dialog({
                    component: DeviceNavigatorDialog,
                    componentProps: {
                        url: navigationUrl,
                    },
                });
            else
                window.open(navigationUrl, '_system');
            return;
        }

        // Open url in native browser
        if (this.block?.properties?.openInBrowser) {
            // Open url in browser
            window.open(navigationUrl, '_system');
            return;
        }

        // Get ad instance
        const NativeBrowser = this.context.renderer.a2u.getDevice()?.getPlugin?.("A2UNativeBrowser");

        // Get window title
        const title = this.context.interpretString(this.block.properties?.windowTitle) || ' ';

        // Get fullscreen flag
        const fullscreen = parseInt(this.block.properties?.fullscreen) === 1;

        // If empty title
        /*if (!title) {
            this.context.renderer.a2u.showError('DeviceNavigator, windowTitle is not a string')
            return;
        }*/

        try {
            // Open url in native browser
            await NativeBrowser.openUrl({
                url: navigationUrl,
                mode: fullscreen ? 'fullscreen' : 'modal',
                title,
            });
        } catch (e) {
            console.error(`DeviceNavigator: ${e.message}`, this.block)
        }
    }
}
