<template>

  <div class="column control-list-editor-cmp q-gutter-y-sm" :class="classesString" :style="stylesString">

    <label class="text-subtitle1 bg-grey-3 q-pa-xs" v-if="title">
      {{title}}
    </label>

    <div v-for="(item, key) of currentValue" :key="key" class="row q-card--bordered ">
      <div class="col q-pa-sm">
        <control-list-item v-model="currentValue[key]" :block="block"/>
      </div>
      <div class="content-center q-pa-xs bg-grey-2">
        <q-btn icon="delete" size="sm" round flat color="negative" @click="deleteItem"/>
      </div>
    </div>

    <div class="q-pt-sm">
      <q-btn icon="add" flat color="primary" @click="addItem">Add</q-btn>
    </div>
  </div>


</template>

<script>

import {renderMixins} from "../../renderMixins";
import {formComponentsMixins} from "../formComponentsMixins";
import ControlListItem from "./ControlListItem.vue";

export default {
  components: {ControlListItem},
  mixins: [renderMixins, formComponentsMixins],
  props: ['block'],
  name: "ControlListEditorCmp",
  computed: {

    /**
     * Title
     * @return {*|string}
     */
    title() {
      return this.block.properties?.title
    }
  },

  methods: {
    /**
     * Add item
     */
    addItem() {
      this.currentValue = (this.currentValue && Array.isArray(this.currentValue)) ? this.currentValue : []
      this.currentValue?.push({})
    },

    /**
     * Delete item
     */
    deleteItem(index) {

      this.$q.dialog({
        title: 'Delete item',
        message: 'Are you sure you want to delete this item?',
        ok: 'Yes',
        cancel: 'No'
      }).onOk(() => {
        this.currentValue.splice(index, 1)
      })
    }
  }
}

</script>

<style lang="scss">
.control-list-editor-cmp {

}
</style>
