<template>
    <template v-for="(block) of items?.filter(i => !i?.deleted)" :key="block.id">
        <component :is="renderer.a2u.getComponentByType(block.type).component" :block="block"/>
    </template>
</template>

<script>

export default {
    name: "AtuComponentsRenderer",

    /**
     * Provide
     */
    provide() {
        return this.data || {}
    },

    /**
     * Inject
     */
    inject: {
        renderer: {
            default: {}
        }
    },
    components: {},
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        items: {
            type: Array,
            default: () => []
        },
    },
}

</script>
